import React from 'react';
import viagemImage from '../../assets/img/uber.jpg';
import android from '../../assets/img/app-store-google-4d63c31a3e.svg';
import IOS from '../../assets/img/app-store-apple-f1f919205b.svg';
import passagerImage from '../../assets/img/passager.jpg';
import './Body.css';

const Body = () => {
	return (
		<>
			<section className='driver'>
				<img
					src={viagemImage}
					alt='N2CAR'
					className='uberImagem'
				/>
				<div className='uberText'>
					<p className='uber1'>
						Motorista administre seu tempo e alcance ganhos de acordo com suas
						necessidades.
					</p>
					<p className='uber2'>
						Obtenha ganhos no seu tempo, seja por meio de entregas, viagens, ou
						ambos, com a flexibilidade de usar seu veículo pessoal ou optar por
						um carro alugado via N2Car.
					</p>

					<a href='https://play.google.com/store/apps/details?id=br.com.n2car.taxi.drivermachine&pcampaignid=web_share'>
						<img
							src={android}
							alt='N2CAR'
							className='android'
						/>
					</a>
				</div>
			</section>
			<section className='passager'>
				<div className='uberText'>
					<p className='uber1'>
						Pasageiro sinta-se confiante em sua jornada, pois nossa sabedoria
						está ao seu lado.
					</p>

					<p className='uber2'>
						Assuma a jornada com confiança, pois em cada viagem, a sabedoria das
						estrelas ilumina seu caminho.
					</p>
					<div>
						<a href='https://play.google.com/store/apps/details?id=br.com.n2car.passenger.drivermachine&pcampaignid=web_share'>
							<img
								src={android}
								alt='N2CAR'
								className='android'
							/>
						</a>
						<a href='https://apps.apple.com/br/app/n2car/id6472857954'>
							<img
								src={IOS}
								alt='N2CAR'
								className='IOS'
							/>
						</a>
					</div>
				</div>
				<img
					src={passagerImage}
					alt='N2CAR'
					className='passagerImagem'
				/>
			</section>
		</>
	);
};

export default Body;
