import styled from 'styled-components';

export const Content = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContentImage = styled.div`
	display: flex;
	width: 80%;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		width: 50%;
	}
`;
