import { useState } from 'react';
import notFound from '../../assets/notFound.json';
import notFound2 from '../../assets/notFound2.json';
import Lottie from 'react-lottie';
import { Content, ContentImage } from './style';

const ContentNotFound = () => {
	const [animateState] = useState({ isStopped: false, isPaused: false });

	const defaultOptions = {
		loop: true,
		autoplay: true,
	};

	return (
		<Content>
			<ContentImage>
				<Lottie
					options={{ ...defaultOptions, animationData: notFound }}
					isStopped={animateState.isStopped}
					isPaused={animateState.isPaused}
				/>
				<Lottie
					options={{ ...defaultOptions, animationData: notFound2 }}
					isStopped={animateState.isStopped}
					isPaused={animateState.isPaused}
				/>
			</ContentImage>
		</Content>
	);
};

export default ContentNotFound;
