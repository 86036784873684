import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';

import ContentNotFound from '../components/ContentNotFound/ContentNotFound';

const MainRoutes = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route
						path='/'
						element={<Home />}
					/>
					<Route
						path='*'
						element={<ContentNotFound />}
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default MainRoutes;
