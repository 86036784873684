import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
:root{
        /* main colors */

        --color-primary-01:#F38C23;
              --color-primary-02:#F5993D;
                    --color-primary-03:#F6A351;

                    
        --color-secundary-01:#9AADBF;
              --color-secundary-02:#A8B8C7;
                    --color-secundary-03:#B4C2CF;




        --links:#0086FF;

        /* validation */

        --success-01: #14213D;
        --success-02: #19294D;
        --success-03: #1E315C;


        --error: #E60000;

        /* colors black */

        --black-0: #000;
        --black-1: #0A0A0A;
        --black-2: #141414;

        /* colors white */


        --white-0: #FFF;
        --white-1: #F5F5F5;
        --white-2: #EBEBEB;


    }





  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body{
      background-color: var(--white-0);
    }

    
  ul,ol,li{
    list-style: none;
  }

  p{
    margin-bottom: 25px;
  }

  a{
    text-decoration: none;
    color: white
  }

  button{
    cursor: pointer;
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: var(--color-secundary-01);
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: var(--color-gray-3);
    border-radius:0 4px 4px 0;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-2);
    border-radius: 10px;
    border: 3px solid var(--color-gray-3);
  }
`;
export default GlobalStyle;
