import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/GlobalStyle';

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<ToastContainer
			position='top-right'
			autoClose={3000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
		/>

		<GlobalStyle />
		<App />
	</React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
