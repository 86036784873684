import React, { useState } from 'react';
import './Footer.css';
import Modal from 'react-modal';

const Footer = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	return (
		<footer className='uber-footer'>
			<div className='uber-content'>
				<div className='help-center'>
					<h3>
						<a href='mailto:contato@n2c.app'>Acesse a Central de Ajuda</a>
					</h3>
				</div>
			</div>
			<div className='footer-bottom'>
				<div className='app-download'>
					<a
						href='https://play.google.com/store/apps/details?id=br.com.n2car.passenger.drivermachine&pcampaignid=web_share'
						target='_blank'
						rel='noopener noreferrer'>
						<button>Baixe o app da N2Car para Android</button>
					</a>
					<button>Baixe o app da N2Car para iOS</button>
				</div>
				<p>© 2023 N2Car Technologies Inc.</p>
			</div>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={closeModal}
				contentLabel='Formulário de E-mail'>
				<h2>Em Construção</h2>
			</Modal>
		</footer>
	);
};

export default Footer;
