import Body from '../../components/Body';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';

const Home = () => {
	return (
		<>
			<Header />
			<Body />
			<Footer />
		</>
	);
};

export default Home;
