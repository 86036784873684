import React from 'react';
import './Header.css';
import viagemImage from '../../assets/img/viagem.jpg';

const Header = () => {
	return (
		<>
			<nav
				data-baseweb='header-navigation'
				role='navigation'
				className='custom-header'>
				<div className='css-kHKPVT'>
					<a
						data-baseweb='link'
						href='https://www.n2c.app/'
						target='_self'
						aria-label='Acesse N2Car.com'
						data-tracking-name='_c2842bf1-4d16-48f3-82aa-68be6d9b6dc2_logo.link_cta1'
						className='css-jHDdnW'>
						<p>N2</p>
					</a>
					<p>Car</p>
				</div>
				<div className='css-bJAboq'>
					<p>
						Em qualquer destino, o aplicativo da
						<span class='destaque-amarelo'> N2</span>Car te conduz com destreza.
					</p>
					<img
						src={viagemImage}
						alt='N2CAR'
						className='css-bGmtfz'
					/>
				</div>
			</nav>
		</>
	);
};

export default Header;
